@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Std';
  src: url('NHaasGroteskDSStd-56It.woff2') format('woff2'),
    url('NHaasGroteskDSStd-56It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Text Std';
  src: url('NHaasGroteskTXStd-56It.woff2') format('woff2'),
    url('NHaasGroteskTXStd-56It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Text Std';
  src: url('NHaasGroteskTXStd-65Md.woff2') format('woff2'),
    url('NHaasGroteskTXStd-65Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Std';
  src: url('NHaasGroteskDSStd-76BdIt.woff2') format('woff2'),
    url('NHaasGroteskDSStd-76BdIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Text Std';
  src: url('NHaasGroteskTXStd-55Rg.woff2') format('woff2'),
    url('NHaasGroteskTXStd-55Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Std';
  src: url('NHaasGroteskDSStd-36XLtIt.woff2') format('woff2'),
    url('NHaasGroteskDSStd-36XLtIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Std';
  src: url('NHaasGroteskDSStd-35XLt.woff2') format('woff2'),
    url('NHaasGroteskDSStd-35XLt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Text Std';
  src: url('NHaasGroteskTXStd-66MdIt.woff2') format('woff2'),
    url('NHaasGroteskTXStd-66MdIt.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Std';
  src: url('NHaasGroteskDSStd-55Rg.woff2') format('woff2'),
    url('NHaasGroteskDSStd-55Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Std';
  src: url('NHaasGroteskDSStd-75Bd.woff2') format('woff2'),
    url('NHaasGroteskDSStd-75Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Text Std';
  src: url('NHaasGroteskTXStd-75Bd.woff2') format('woff2'),
    url('NHaasGroteskTXStd-75Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Text Std';
  src: url('NHaasGroteskTXStd-76BdIt.woff2') format('woff2'),
    url('NHaasGroteskTXStd-76BdIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NHaasGroteskDSPro-75Bd.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NHaasGroteskDSPro-35XLt.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}
