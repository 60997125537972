#root {
  position: relative;
}
html {
  overflow: hidden;
}

body {
  color: #262525;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}
